.home .header {
  /* background-image: ''url(/image-header/dogs.jpg)''; */
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding: 100px 25px;
  text-align: center;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}
.header .title {
  font-weight: bold;
  font-size: 5rem;
}

.slideanim {
  visibility: hidden;
}
.slide {
  animation-name: slide;
  -webkit-animation-name: slide;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  visibility: visible;
}

.vendor:hover {
  /* border: 1px solid gray; */
  box-shadow: 5px 5px 5px rgb(214, 232, 240);
}

/* .partners a: hover {} */

@keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}
@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

@media screen and (max-width: 480px) {
  .logo {
    font-size: 150px;
  }
  .header .title {font-size: 3rem;}
  .vendor {
    width: 100px !important;
    height: 100px !important
  }
}
