@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;300;500;700&family=Roboto:ital,wght@0,300;0,400;0,900;1,300;1,500&display=swap);
.home .header {
  /* background-image: ''url(/image-header/dogs.jpg)''; */
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding: 100px 25px;
  text-align: center;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}
.header .title {
  font-weight: bold;
  font-size: 5rem;
}

.slideanim {
  visibility: hidden;
}
.slide {
  animation-name: slide;
  -webkit-animation-name: slide;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  visibility: visible;
}

.vendor:hover {
  /* border: 1px solid gray; */
  box-shadow: 5px 5px 5px rgb(214, 232, 240);
}

/* .partners a: hover {} */

@keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}
@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

@media screen and (max-width: 480px) {
  .logo {
    font-size: 150px;
  }
  .header .title {font-size: 3rem;}
  .vendor {
    width: 100px !important;
    height: 100px !important
  }
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}
/**
 * define App.js style for 
 *  Navigation tab,
 *  Footer
 * 
 */

.navbar-brand {
  font-weight: bold;
}

.nav-bg-blue {
  /* background-color: deepskyblue;  */
  /* background-color: rgba(200,180,0, 0.9) !important;  */
  background-color: white;
}

.navbar-brand a {
  color: white;
  text-decoration: none;
}
.navbar-brand a:hover {
  color: aliceblue;
  text-decoration: none;
}

/*
.navbar-nav a, .navbar-text {
  padding: 20px !important;
  color: white !important;
}

.navbar-nav a:hover {
  color: rgb(241, 184, 163) !important;  
}

.dropdown-menu a {  
  padding: 5px 10px !important;
  color: steelblue !important
} */

.nav-link {
  padding: 20px !important;
  font-weight: 500;
  /* color: rgba(255, 255, 255, 0.6) !important; */
  /* color: white !important; */
  color: steelblue !important;
}

.nav-link:hover, .nav-link.active {
  /* color: white !important; */
  color: rgb(200, 50, 50) !important;
}

.dropdown-item {
  color: gray !important;
}

.dropdown-item:hover, .dropdown-item.active {
  color: skyblue !important;
  background-color: white !important;
}

@media screen and (min-width: 960px) {  
  /* .navbar {
    padding: .5rem 10rem !important;
  } */
}

/**
  define Global style for 
    Fonts: Roboto, Gothic
    font-size 1.2rem, 1.5rem 1.6rem
    weight 
    background-color: 
      navbar:       steelblue   #4682b4 rgb(70,130,180)
      footer:                   #315a7d rgb(49,90,125)
      section bar:  skyblue     #87ceeb rgb(135,206,235)
    text color:                 #444444
 */

body {
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif;
   font-family: Lato, Verdana, sans-serif; */
  font-family: "Roboto", sans-serif;
  font-family: "Gothic A1", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input[type="file"] {
  width: 100%;
}

h1 {
  padding: 30px 0;
  text-align: center;
}
h2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}
h5 {
  font-weight: 300;
}

h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.4rem;
  color: rgb(15, 112, 192);
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.4rem;
}

.card-title {
  font-weight: 600;
}

table ul {
  margin: 0;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2.4rem;
  }
  h2 {
    font-size: 2.0rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.2rem;
  }
}

